import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

import AttendanceDriveBanner from './images/attendance_drive_start_banner.jpg';

class AttendanceDrive2023 extends React.Component<{}, { appearances: VehicleAppearanceDictionary }> {

    constructor(props: {}) {
        super(props);
        this.loadVehicleAppearances = this.loadVehicleAppearances.bind(this);
        this.state = {
            appearances: { 
                september_23: null, 
                october_23:  null,
                november_23:  null,
                december_23:  null,
                january_24: null,
                february_24:  null,
                march_24:  null,
                april_24:  null,
                may_23:  null 
            }
        };
    }

    componentDidMount(): void {
        this.loadVehicleAppearances();
    }

    async loadVehicleAppearances() {
        const response = await fetch("https://c6parvviaa.execute-api.us-west-1.amazonaws.com/ad-23"); 
        const appearances: AppearanceResponse = await response.json();
        console.log("Received from API: " + appearances);        
        delete (appearances as any).items.september;
        this.setState({ appearances: appearances.items });
    }


    render() {
        const tableItems:React.ReactElement[] = [];
        const allAppearances: VehicleAppearance[] = [];
        for (const key in this.state.appearances) {
            var monthData = (this.state.appearances as any)[key] as VehicleAppearanceSet | null;
            if (monthData && monthData.dates) {
                monthData.dates.forEach(appearance => {
                    allAppearances.push(appearance);
                })
            }
            allAppearances.sort((a: VehicleAppearance, b: VehicleAppearance) => {
                return new Date(a.date).getTime() - new Date(b.date).getTime();
            })
        }

        allAppearances.forEach(appearance => {
            tableItems.push((
                <li
                    style={
                        { 
                            border: '1px gray solid', 
                            maxWidth: '460px',
                            margin: '0 auto',
                            padding: '8px',
                            marginBottom: '10px',
                            backgroundColor: 'rgba(20, 50, 170, 0.2)'
                        }}
                    key={appearance.id}>
                        <span style={{fontWeight: 'bold'}}>{appearance.school}</span>
                        <br />
                        {new Date(appearance.date).toLocaleString('en-US', { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric'  })}
                        <br />
                        {appearance.time.replaceAll("pm", "PM").replaceAll("am", "AM")}
                        <br />
                        <span style={{ fontSize: '0.8em'}}>Location: {appearance.location_for_vehicle}</span>
                </li>
                ));
        });


        return (
            <div className='standard'>
                <Container
                    fluid={true}>
                        <Container>
                            <Row>
                                <Col>
                                <h1>3rd Annual Attendance Drive</h1>

                                    <div 
                                        style={{ maxWidth: '800px', margin: '0 auto'}}>
                                            <img 
                                        className='animate__animated animate__fadeIn animate__slow img-fluid'
                                        style={{ }}
                                        src={AttendanceDriveBanner} />
                                        </div>
                                    <p><br /></p>
                                    <h4>Attendance Drive Vehicle Appearances</h4>
                                    <p>
                                        Looking to catch the Attendance Drive vehicle at 
                                        it's next location? The calendar below shows the 
                                        locations confirmed for the 2023-24 school year so far. 
                                        Be sure to check back as the schedule will update as new 
                                        appearances are confirmed.
                                    </p>
                                    <ul style={
                                        { 
                                            listStyleType: 'none', 
                                            paddingLeft: '0' 
                                        }}>
                                        {tableItems}
                                    </ul>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
            </div>
        )
    }
}

export default AttendanceDrive2023;

interface VehicleAppearanceDictionary {
    september_23: VehicleAppearanceSet | null;
    october_23: VehicleAppearanceSet | null;
    november_23: VehicleAppearanceSet | null;
    december_23: VehicleAppearanceSet | null;
    january_24: VehicleAppearanceSet | null;
    february_24: VehicleAppearanceSet | null;
    march_24: VehicleAppearanceSet | null;
    april_24: VehicleAppearanceSet | null;
    may_23: VehicleAppearanceSet | null;
}

interface VehicleAppearanceSet {
    id: string;
    monthName: string;
    dates: VehicleAppearance[];
}

interface VehicleAppearance {
    id: string;
    date: string;
    location_for_vehicle: string;
    school: string;
    time: string;
};

interface AppearanceResponse {
    success: string;
    items: VehicleAppearanceDictionary;
}