import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export default function ASGBIGRules() {
    return (
        <div className='standard'>
            <Container>
                <h1>AwesomeSPOT Instagram Contest Rules</h1>
                <Row>
                    <Col>
                        <div dangerouslySetInnerHTML={{ __html: contestHTML }} />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

let contestHTML =  `
<div style="max-width:600px;margin: 0 auto;">
            <br />NO PURCHASE IS NECESSARY TO ENTER OR WIN. A PURCHASE DOES NOT INCREASE THE CHANCES OF WINNING.
        
            <br />1. Eligibility: This Campaign is open only to those who sign up at the www.ingsatram.com/modesto_toyota and who are 18 years or older as of the date of entry. The Campaign is only open to legal residents of United States, and is void where prohibited by law. Employees of  Modesto Toyota, its affiliates, subsidiaries, advertising and promotion agencies, and suppliers, (collectively the "Employees"), and immediate family members and/or those living in the same household of Employees are not eligible to participate in the Campaign. The Campaign is subject to all applicable federal, state, and local laws and regulations. Void where prohibited.
        
            <br />2. Agreement to Rules: By participating, the Contestant ("You") agree to be fully unconditionally bound by these Rules, and You represent and warrant that You meet the eligibility requirements. In addition, You agree to accept the decisions of Modesto Toyota as final and binding as it relates to the content of this Campaign.
        
            <br />3. Campaign Period: Entries will be accepted online starting on 08/12/2022 and ending 08/15/2022. All online entries must be received by 08/15/2020 1PM PST.
        
            <br />4. How to Enter: The Campaign must be entered by submitting an entry by adding a comment to the post at the campaign url. The entry must fulfill all Campaign requirements, as specified, to be eligible to win a prize. Entries that are incomplete or do not adhere to the rules or specifications may be disqualified at the sole discretion of Modesto Toyota. You may enter only once. You must provide the information requested. You may not enter more times than indicated by using multiple email addresses, identities, or devices in an attempt to circumvent the rules. If You use fraudulent methods or otherwise attempt to circumvent the rules, your submission may be removed from eligibility at the sole discretion of Modesto Toyota.
        
            <br />5. Prizes: The Winner(s) of the Campaign (the "Winner") will receive (1) $150 Galaxy Movie Theater Gift Certificate. No cash or other prize substitution shall be permitted except at Modesto Toyota's discretion. The prize is nontransferable. Any and all prize-related expenses, including without limitation any and all federal, state, and/or local taxes, shall be the sole responsibility of Winner. No substitution of prize or transfer/assignment of prize to others or request for the cash equivalent by Winner is permitted. Acceptance of prize constitutes permission for Modesto Toyota to use Winner's name, likeness, and entry for purposes of advertising and trade without further compensation, unless prohibited by law.
        
            <br />6. Odds: The odds of winning depend on the number of eligible entries received.
        
            <br />7. Winner Selection and Notification: Winner will be selected by a random drawing under the supervision of Modesto Toyota. Winner will be notified by posting an update on Facebook or Instagram on 08/15/2022 following selection of Winner. Modesto Toyota shall have no liability for Winner's failure to receive notices due to spam, junk e-mail or other security settings or for Winner's provision of incorrect or otherwise non-functioning contact information. If Winner cannot be contacted, is ineligible, fails to claim the prize within 5 days from the time award notification was sent, or fails to timely return a completed and executed declaration and release as required, the prize may be forfeited and an alternate Winner selected. Receipt by Winner of the prize offered in this Campaign is conditioned upon compliance with any and all federal, state, and local laws and regulations. ANY VIOLATION OF THESE OFFICIAL RULES BY WINNER (AT Modesto Toyota’s SOLE DISCRETION) WILL RESULT IN WINNER'S DISQUALIFICATION AS WINNER OF THE CAMPAIGN, AND ALL PRIVILEGES AS WINNER WILL BE IMMEDIATELY TERMINATED.
        
            <br />8. Rights Granted by You: By entering this content (e.g., photo, video, text, etc.), You understand and agree that Modesto Toyota, anyone acting on behalf of Modesto Toyota, and Modesto Toyota's licensees, successors, and assigns, shall have the right, where permitted by law, to print, publish, broadcast, distribute, and use in any media now known or hereafter developed, in perpetuity and throughout the World, without limitation, your entry, name, portrait, picture, voice, likeness, image, statements about the Campaign, and biographical information for news, publicity, information, trade, advertising, public relations, and promotional purposes. without any further compensation, notice, review, or consent. By entering this content, You represent and warrant that your entry is an original work of authorship, and does not violate any third party's proprietary or intellectual property rights. If your entry infringes upon the intellectual property right of another, You will be disqualified at the sole discretion of Modesto Toyota. If the content of your entry is claimed to constitute infringement of any proprietary or intellectual proprietary rights of any third party, You shall, at your sole expense, defend or settle against such claims. You shall indemnify, defend, and hold harmless Modesto Toyota from and against any suit, proceeding, claims, liability, loss, damage, costs or expense, which Modesto Toyota may incur, suffer, or be required to pay arising out of such infringement or suspected infringement of any third party's right.
        
            <br />9. Terms & Conditions: Modesto Toyota reserves the right, in its sole discretion, to cancel, terminate, modify or suspend the Campaign should virus, bug, non-authorized human intervention, fraud, or other cause beyond Modesto Toyota's control corrupt or affect the administration, security, fairness, or proper conduct of the Campaign. In such case, Modesto Toyota  may select the Winner from all eligible entries received prior to and/or after (if appropriate) the action taken by Modesto Toyota. Modesto Toyota reserves the right, in its sole discretion, to disqualify any individual who tampers or attempts to tamper with the entry process or the operation of the Campaign or website or violates these Terms & Conditions. Modesto Toyota has the right, in its sole discretion, to maintain the integrity of the Campaign, to void votes for any reason, including, but not limited to: multiple entries from the same user from different IP addresses; multiple entries from the same computer in excess of that allowed by Campaign rules; or the use of bots, macros, scripts, or other technical means for entering. Any attempt by an entrant to deliberately damage any website or undermine the legitimate operation of the Campaign may be a violation of criminal and civil laws. Should such attempt be made, Modesto Toyota reserves the right to seek damages to the fullest extent permitted by law.
        
            <br />10. Limitation of Liability: By entering, You agree to release and hold harmless Modesto Toyota and its subsidiaries, affiliates, advertising and promotion agencies, partners, representatives, agents, successors, assigns, employees, officers, and directors from any liability, illness, injury, death, loss, litigation, claim, or damage that may occur, directly or indirectly, whether caused by negligence or not, from: (i) such entrant's participation in the Campaign and/or his/her acceptance, possession, use, or misuse of any prize or any portion thereof; (ii) technical failures of any kind, including but not limited to the malfunction of any computer, cable, network, hardware, or software, or other mechanical equipment; (iii) the unavailability or inaccessibility of any transmissions, telephone, or Internet service; (iv) unauthorized human intervention in any part of the entry process or the Promotion; (v) electronic or human error in the administration of the Promotion or the processing of entries.
        
            <br />11. Disputes: THIS Campaign IS GOVERNED BY THE LAWS OF USA AND California, WITHOUT RESPECT TO CONFLICT OF LAW DOCTRINES. As a condition of participating in this Campaign, participant agrees that any and all disputes that cannot be resolved between the parties, and causes of action arising out of or connected with this Campaign, shall be resolved individually, without resort to any form of class action, exclusively before a court located in California having jurisdiction. Further, in any such dispute, under no circumstances shall participant be permitted to obtain awards for, and hereby waives all rights to, punitive, incidental, or consequential damages, including reasonable attorney's fees, other than participant's actual out-of-pocket expenses (i.e. costs associated with entering this Campaign). Participant further waives all rights to have damages multiplied or increased.
        
            <br />12. Privacy Policy: Information submitted with an entry is subject to the Privacy Policy stated on the Modesto Toyota website. To read the Privacy Policy, visit our website at mchenryvillage.com.
        
            <br />13. Winners List: To obtain a copy of the Modesto Toyota  4513 McHenry Ave, Modesto, CA 95356
Requests must be received no later than 08/15/2022.
        
            <br />14. Sponsor: The Sponsor of the Campaign is Modesto Toyota  4513 McHenry Ave, Modesto, CA 95356.
        
            <br />15. Facebook: The Campaign hosted by Modesto Toyota is in no way sponsored, endorsed, administered by, or associated with Facebook.
        
            <br />16. By entering the contest, You, the Contestant, have affirmatively reviewed, accepted, and agreed to all of the Official Rules.
    </div>
</div>
`