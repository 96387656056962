import * as React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Routes
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Main from './Main';
import { Col, Container, Row } from 'react-bootstrap';
import { Header } from './Header';

import '@google/model-viewer';
import CarSeatAR from './CarSeatAR';
import Footer from './Footer';
import ASGBFBRules from './AwesomeSPOTGBContest-FB';
import ASGBIGRules from './AwesomeSPOTGBContest-IG';
import PalisadesContest from './PalasidesContest2022-FB';
import PalisadesContest24 from './PalasidesContest2024-FB';
import PalisadesContest24p2 from './PalasidesContest2024-p2-FB';                                                                                                                                                                                                                                                                                                                           
import AttendanceDrive23 from './AttendanceDrive2023';

function App() {
    return (
        <Router>
            <div className="App">
                <Container>
                    <Header />
                </Container>
                <Routes>
                    <Route path="/" element={<Main />} />
                    <Route path="attendance-drive-2023" element={<AttendanceDrive23 />} />
                    <Route path="car-seat-ar" element={<CarSeatAR />} />
                    <Route path='awesome-spot-fb-contest' element={<ASGBFBRules />} />
                    <Route path="awesome-spot-ig-contest" element={<ASGBIGRules />} />
                    <Route path="palisades-2023" element={<PalisadesContest />} />
                    <Route path="palisades-2024" element={<PalisadesContest24 />} />
                    <Route path="palisades-2024-p2" element={<PalisadesContest24p2 />} />
                </Routes>
                <br />
                <Footer />
            </div>
        </Router>
    );
}

export default App;