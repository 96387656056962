import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';


export default function CarSeat() {
    return (
        <div className="standard">
            <Container
                fluid={true}>
                <Container>
                    <h1>Car Seat Checkup AR</h1>
                    <Row>
                        <Col>
                            <div
                                style={{ width: '100%', maxWidth: '400px', margin: '0 auto' }}
                                dangerouslySetInnerHTML={{ __html: modelViewer }}>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <p style={{
                                maxWidth: 450,
                                margin: '0 auto',
                                textAlign: 'center',
                                marginTop: '8px'
                            }}>
                                If viewing on an AR-compatible device, tap
                                the icon in the bottom right to view the
                                car seat in your room.
                    </p>
                            <Button
                                className="mt-3 mt-btn"
                                href="/">
                                Back to Events Page
                        </Button>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    )
}


let modelViewer = `
<model-viewer 
    class="model-viewer"
    src="/content/3d/car_seat_ar.glb" 
    ar 
    ar-modes="webxr scene-viewer quick-look" 
    ar-scale="auto" 
    camera-controls 
    alt="Car Seat in 3d" 
    ios-src="/content/3d/car_seat_ar.usdz">
</model-viewer>
`