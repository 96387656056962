import React, { useState } from 'react';
import "animate.css";
import { Button, Carousel, Col, Container, Row } from 'react-bootstrap';

// import CarSeatFeaturedImage from './images/car_seat_checkup_2021_featured.jpg';
import CarSeatSpotlightImage from './images/car_seat_spotlight_render.jpg';
// import TableMaskImage from './images/table_mask_render.jpg';
import AdoptionImage from './images/adopt-a-palooza_2021.jpg';
import AwesomeSpotMTImage from './images/hero_comp_v1.jpg';
import AwesomeSpotOverviewImage from './images/awesome-spot-overview.jpg';
import AwesomeSpotForestImage from './images/awesome-spot-forest.jpg';
import CarSeatImage from './images/hero_comp_v2.jpg';
import IFImage from './images/if_expo_2022.jpg'
import CarSeat2023 from './images/MTCarSeat2023_save_the_date_v1.jpg';
import AttendanceDriveBanner from './images/attendance_drive_start_banner.jpg';

export default function Main() {
    const [showAllInfo, setShowAllInfo] = useState(false);
    return (
        <div className="main">
            <Container
                fluid={true}>
                <Container>
                    <h1 className="mb-4">Community Events</h1>
                    {/*
                    <p>Check back for more info on upcoming events.</p>
                    */}
                    <div className='hero-content'>
                        <img 
                            className='animate__animated animate__fadeIn animate__slow'
                            src={AttendanceDriveBanner} />
                        <h3 className='mt-4 overhidden-header'>Attendance Drive 2023-2024</h3>
                        <p>
                            Attendance Drive is back! We are excited to announce the 3rd annual Attendance Drive. 
                            We are partnering with Stanislaus County Office of Education to provide a new 2024 Toyota 
                            Corolla as a prize reward for great school attendance. We have also partnered with TSM 
                            Insurance and Vintage Car Wash, and the winner will receive a year of car insurance and 
                            a year of free car washes. 
                            <br />
                            Attendance is key to success, so get in the driver's seat!
                            <br />
                            <Button
                                className='mt-2 mt-btn signup-btn adopt-btn'
                                block={true}
                                size='lg'
                                onClick={() => { window.location.href = "/attendance-drive-2023"}}>
                                    Learn More
                                </Button>
                            
                            <br /><br />
                            In May, a drawing of all public high school students in Stanislaus County will take 
                            place at the high schools for students who had perfect attendance. A student's name 
                            is entered into a drawing each month they have perfect attendance. Winning names will 
                            be submitted to SCOE for a final drawing in May. See full rules at: <a href="https://www.stancoe.org/families/attendance-matters" target="new">https://www.stancoe.org/families/attendance-matters</a>
                        </p>
                    </div>
                    <hr />
                    <div className="hero-content">
                        <img
                            className=""
                            src={AwesomeSpotMTImage} />
                        <h3 className="mt-4 overridden-header">The AWESOME SPOT Playground <br /><span className="smallupcase">at Beyer Park</span></h3>
                        <div>
                            {showAllInfo ? null : (
                                <div>
                                    <p>
                                        Learn more about the Awesome Spot Playground's
                                        mission and find out why an inclusive park would
                                        benefit Modesto.
                                    </p>
                                    <Button
                                        className="mt-2 mt-btn signup-btn adopt-btn"
                                        block={true}
                                        disabled={false}
                                        size="lg"
                                        onClick={() => { setShowAllInfo(true) }}>
                                        Learn More
                                    </Button>
                                </div>
                            )}
                            {showAllInfo ? (
                                <div className="animate__animated animate__fadeIn animate__slow">
                                    <Carousel fade={false} slide={false}>
                                        <Carousel.Item>
                                            <a href={AwesomeSpotOverviewImage} target="new">
                                                <img className="mb-3 mt-2" src={AwesomeSpotOverviewImage} alt="Overview of proposed playground at Beyer park" />
                                            </a>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <a href={AwesomeSpotForestImage} target="new">
                                                <img className="mb-3 mt-2" src={AwesomeSpotForestImage} alt="Proposed playground forest feature at Beyer park" />
                                            </a>
                                        </Carousel.Item>
                                    </Carousel>
                                    <p>
                                        The AWESOME SPOT Playground is an inclusive playground planned for Beyer Park in Modesto. The
                                        playground will be a destination for children and adults of all abilities.
                                        <br /><br />
                                        "Play is very much a social process and the opportunity to be included should be available to every child!"
                                        <br /><br />
                                        We are commited to helping make our community feel more like home to all, ensuring no one feels left out.
                                        <br /><br />
                                    </p>
                                    <h5>Looking to help out the cause?</h5>
                                    <p>
                                        Follow the link below to donate to the cause and help bring inclusivity to the playgrounds of Modesto.
                                    </p>
                                    <Button
                                        id="signup-btn"
                                        className="mt-2 mt-btn signup-btn adopt-btn"
                                        block={true}
                                        disabled={false}
                                        size="lg"
                                        target="new"
                                        href="http://www.theawesomespotplayground.com/donate-now.html">
                                        Donate
                                    </Button>
                                </div>
                            ) : null}
                        </div>
                        <h3 className="mt-5">PAST EVENTS</h3>
                        <div className='past-event-card text-sm-left'>
                            <Row>
                                <Col
                                    xs={12}
                                    sm={6}>
                                    <img
                                        className="animate__animated animate__fadeIn animate__slow"
                                        src={IFImage} />
                                </Col>
                                <Col
                                    xs={12}
                                    sm={6}>
                                    <h4 className="mt-2">Informative Fisherman's Expo 2023<br /><span className="smallupcase">Saturday, May 27, 2023<br />10am - 4pm</span></h4>
                                    <div>
                                        <p>
                                            Fun, free family event,
                                            Informative Fisherman’s Expo took place on Saturday, May 27th
                                            from 10am to 4pm!
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <hr />
                        <div className="past-event-card text-sm-left">
                            <Row>
                                <Col
                                    xs={12}
                                    sm={6}>
                                    <div
                                        className="">
                                        <img src={CarSeatImage} alt="Car seat sitting next to Toyota van" />
                                    </div>
                                </Col>
                                <Col
                                    xs={12}
                                    sm={6}>
                                    <h4 className="mt-2">Car Seat Checkup Event 2023</h4>
                                    <p>
                                        Car seat checkup event at Modesto Toyota featuring Safe Kids Stanislaus which took place on March 5, 2023.
                                    </p>
                                </Col>
                            </Row>
                        </div>
                        <hr />
                        <div className='past-event-card text-sm-left'>
                            <Row>
                                <Col
                                    xs={12}
                                    sm={6}>
                                    <img
                                        className="animate__animated animate__fadeIn animate__slow"
                                        src={IFImage} />
                                </Col>
                                <Col
                                    xs={12}
                                    sm={6}>
                                    <h4 className="mt-2">Informative Fisherman's Expo 2022<br /><span className="smallupcase">Saturday, April 23, 2022<br />10am - 4pm</span></h4>
                                    <div>
                                        <p>
                                            Fun, free family event,
                                            Informative Fisherman’s Expo took place on Saturday, April 23rd
                                            from 10am to 4pm!
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <hr />
                        <div className="past-event-card text-sm-left">
                            <Row>
                                <Col
                                    xs={12}
                                    sm={6}>
                                    <div
                                        className="">
                                        <img src={CarSeatImage} alt="Collage of dogs, cats, guinea pigs and hamsters" />
                                    </div>
                                </Col>
                                <Col
                                    xs={12}
                                    sm={6}>
                                    <h4 className="mt-2">Car Seat Checkup Event 2022</h4>
                                    <p>
                                        Car seat checkup event at Modesto Toyota featuring Safe Kids Stanislaus which took place on April 11, 2022.
                                    </p>
                                </Col>
                            </Row>
                        </div>
                        <hr />
                        <div className="past-event-card text-sm-left">
                            <Row>
                                <Col
                                    xs={12}
                                    sm={6}>
                                    <div
                                        className="clipped-image">
                                        <img src={AdoptionImage} alt="Collage of dogs, cats, guinea pigs and hamsters" />
                                    </div>
                                </Col>
                                <Col
                                    xs={12}
                                    sm={6}>
                                    <h4 className="mt-2">Adopt-a-Palooza 2021</h4>
                                    <p>
                                        Adoption event held at the dealership
                                        on August 14, 2021 in partnership with
                                        Stanislaus Animal Services (<a href="http://www.stanislausanimalservices.com/" target="new">Link</a>).
                                    </p>
                                </Col>
                            </Row>
                        </div>
                        <hr />
                        <div className="car-seat-spotlight text-sm-left">
                            <Row>
                                <Col
                                    xs={12}
                                    sm={6}>
                                    <img src={CarSeatSpotlightImage} alt="Car seat on table" />
                                </Col>
                                <Col
                                    xs={12}
                                    sm={6}>
                                    <h4 className="mt-2">Car Seat In Your Room</h4>
                                    <p>
                                        Tap below to place the Car Seat Checkup Event Car Seat in
                                        your room using AR or download the full AR experience in the App
                                        Store / Play Store. View it in 3D on other non-AR devices. <br /><br /><small>A WiFi connection is recommended.</small>
                                    </p>
                                    <Button
                                        id="ar-button"
                                        className="mt-2 mt-btn ar-button"
                                        block={true}
                                        href="/car-seat-ar"
                                        size="sm">
                                        View in 3D
                                    </Button>
                                    <Button
                                        id="ar-app-button"
                                        className="mt-2 mt-btn ar-button"
                                        block={true}
                                        href="http://toappsto.re/arsafety"
                                        size="sm">
                                        Download Mobile App
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container>
            </Container>
        </div>
    )
}