import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export const Footer = () => {
    return (
        <footer>
            <Container>
                <Row>
                    <Col>
                        {/* <small 
                                style={{ fontSize: 10}} 
                                className="footer-legal">
                                WARNING: Operating, servicing and maintaining a passenger 
                                vehicle or off-road vehicle can expose you to chemicals 
                                including engine exhaust, carbon monoxide, phthalates, 
                                and lead, which are known to the State of California 
                                to cause cancer and birth defects or other reproductive 
                                harm. To minimize exposure, avoid breathing exhaust, do 
                                not idle the engine except as necessary, service your 
                                vehicle in a well-ventilated area and wear gloves or 
                                wash your hands frequently when servicing your vehicle. 
                                For more information go to &nbsp;
                                <a href="http://www.P65Warnings.ca.gov/passenger-vehicle" target="new" rel="noreferrer noopener">
                                    www.P65Warnings.ca.gov/passenger-vehicle
                                </a>.
                            </small> */}
                    </Col>
                </Row>
                <Row>
                    <Col
                        className="mt-3"
                        style={{ textAlign: 'center' }}>
                        <small>&copy;&nbsp;2023 Modesto Toyota. All Rights Reserved.</small>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer;