import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import logo from './images/logo.png';
import toyotaLogo from './images/toyota_logo.png';

import fbLogo from './images/fb-logo.png';
import twitterLogo from './images/twitter-logo.png';
import instaLogo from './images/instagram-logo.png';
import youtubeLogo from './images/youtube-logo.png';

const socialLogos: SocialLogo[] = [
    {
        image: fbLogo,
        alt: "Facebook",
        url: 'https://www.facebook.com/ModestoToyota/',
    },
    {
        image: twitterLogo,
        alt: "Twitter",
        url: 'https://twitter.com/modestotoyota',
    },
    {
        image: instaLogo,
        alt: "Instagram",
        url: 'https://www.instagram.com/modesto_toyota/'
    },
    {
        image: youtubeLogo,
        alt: "Youtube",
        url: 'https://www.youtube.com/modesto_toyota'
    }
]

export const Header = () => {
    return (
        <header className="header">
            <br />
            <Container>
                <Row>
                    <Col
                    xs={6}
                        sm={4}
                        md={5}
                        lg={5}>
                        <a
                            href="https://www.modestotoyota.com"
                            target="new"
                            rel="noreferrer noopener"
                            style={{ display: 'inline' }}>
                            <img
                                className="toyota-logo"
                                src={toyotaLogo}
                                alt="Toyota logo" />
                        </a>
                        <a
                            style={{ display: 'inline' }}
                            rel="noreferrer noopener"
                            href="https://www.modestotoyota.com"
                            target="new">
                            <img
                                className="mod-toyota-logo"
                                src={logo}
                                alt="Modesto toyota logo" />
                        </a>
                    </Col>
                    <Col
                        xs={6}
                        sm={{ span: 5, offset: 3}}
                        md={{ span: 5, offset: 2}}
                        lg={{ span: 5, offset: 2}}>
                        <ul
                            className="social-logos">
                            {socialLogos.map((socialLogo, index) => {
                                return (
                                    <li
                                        className="social-logo-icon"
                                        key={index + "_" + socialLogo.image}>
                                            <a
                                                href={socialLogo.url}
                                                target="new"
                                                rel="noreferrer noopener">
                                                    <img 
                                                        src={socialLogo.image}
                                                        alt={socialLogo.alt} /> 
                                            </a>
                                    </li>
                                )
                            })}
                        </ul>
                    </Col>
                </Row>
            </Container>
        </header>
    )
}

interface SocialLogo {
    image: string;
    alt: string;
    url: string;
}